import React from 'react'
import PropTypes from 'prop-types'
import { colors, presets } from '../utils/theme'
import { rhythm } from '../utils/typography'
import Checkmark from '../img/icons/checkmark-green.svg'

const styles = {
  benefits: {
    margin: '-15px',
    padding: `0 ${rhythm(1.5)} ${rhythm(1)}`,
    display: 'flex',
    flexDirection: 'column',
    [presets.Tablet]: {
      flexDirection: 'row',
    }
  },
  benefitRowItem: {
    margin: '15px',
    flex: 1,
  },
  benefitItem: {
    border: `1px solid ${colors.blueGreen}`,
    borderRadius: '20px',
    padding: '24px',
    justifySelf: 'stretch',
  },
  benefitTitleRow: {
    display: 'flex',
    marginBottom: '16px',
  },
  icon: {
    width: rhythm(1.125),
    height: rhythm(1.125),
    margin: 0,
  },
  text: {
    marginLeft: '8px',
    lineHeight: rhythm(1.125),
    fontSize: rhythm(0.8),
    fontWeight: 600,
  },
  subText: {
    fontSize: '16px',
  },
}

const Benefit = ({ text, subText, style }) => (
  <dl css={[styles.benefitItem, style]}>
    <div css={styles.benefitTitleRow}>
      <img css={styles.icon} src={Checkmark} /> <dt css={styles.text}>{text}</dt>
    </div>
    <dd css={styles.subText}>{subText}</dd>
  </dl>
)

const TechProductBenefits = ({ benefits, outerCss }) => {
  return (
    <div css={outerCss}>
      <div css={styles.benefits}>
        {benefits.map((b, idx) => <Benefit key={idx} style={styles.benefitRowItem} {...b} />)}
      </div>
    </div>
  )
}

TechProductBenefits.propTypes = {
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      subText: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default TechProductBenefits
