import React from 'react'
import Button from './Button'
import { themeStyles, colors, presets } from '../utils/theme'
import LoanFlowLogo from '../img/loanflow/loanflow_logo.svg'
import { rhythm } from '../utils/typography'
import Youtube from './Youtube'


const styles = {
  block: {
    display: 'flex',
    padding: `${rhythm(2.5)} ${rhythm(1.5)}`,
    flexDirection: 'column',
    [presets.Tablet]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  titleHolder: {
    display: 'flex',
    paddingBottom: 0,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    flexBasis: 'unset',
    marginBottom: rhythm(2),
    [presets.Tablet]: {
      alignItems: 'flex-start',
      textAlign: 'start',
      maxWidth: '400px',
      marginBottom: 0,
    }
  },
  showcaseVideoHolder: {
    backgroundColor: 'transparent',
    width: '100%',
    maxWidth: '700px',
    padding: 0,
    [presets.Tablet]: {
      borderRadius: rhythm(1),
      backgroundColor: colors.blueGreen,
      padding: `${rhythm(0.75)}`,
      margin: 'auto',
    },
  },
  video: {
    borderRadius: rhythm(0.5),
    [presets.Tablet]: {
      borderRadius: rhythm(1),
    }
  },
  logo: {
    width: '240px',
    marginBottom: rhythm(1),
  },
  title: {
    fontSize: rhythm(1.75),
    fontWeight: 300,
    marginBottom: rhythm(1),
    padding: 0,
    width: rhythm(17),
    color: colors.darkGreen,
  },
  buttonContainer: {
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
  },
  button: {
    border: 'none',
    cursor: 'pointer',
  }
}

const logos = {
    'loanflow': LoanFlowLogo,
}

const LicenseProductPageTitle = ({ logo, title, actionText, onClick=null, videoUrl=null }) => {
  return (
    <div css={themeStyles.contentWidth}>
      <div css={styles.block}>
        <div css={styles.titleHolder}>
          <img css={styles.logo} src={logos[logo]} alt='LoanSnap'/>
          <p css={styles.title}>
            {title}
          </p>
          <div css={styles.buttonContainer}>
            <Button
              text={actionText}
              to='/get-your-license-options/'
              analyticsTargetName='Get Your License Options'
            />
          </div>
        </div>
        <div css={styles.showcaseVideoHolder}>
          <Youtube url={videoUrl} outerCss={styles.video}/>
        </div>
      </div>
    </div>
  )
}

export default LicenseProductPageTitle
