import React from 'react'
import PropTypes from "prop-types";

const styles = {
    videoHolder: {
        overflow: 'hidden',
        paddingBottom: '56.25%', // 16x9 aspect ratio
        position: 'relative',
        height: '0',
    },
    iframe: {
        left: '0',
        top: '0',
        height: '100%',
        width: '100%',
        position: 'absolute',
    },
}

const getVideoId = (url) => {
  const _url = new URL(url)
  switch (true) {
    case _url.hostname.endsWith('youtu.be'):
      return _url.pathname.slice(1)
    case _url.hostname.endsWith('youtube.com'):
      return _url.searchParams.get('v')
  }
}

const Youtube = ({ url, outerCss }) => (
  <div css={[styles.videoHolder, outerCss]}>
    <iframe
      css={styles.iframe}
      width="720"
      height="480"
      src={`https://www.youtube.com/embed/${getVideoId(url)}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  </div>
);

Youtube.propTypes = {
  url: PropTypes.string.isRequired
};

export default Youtube;
