import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import Layout from '../components/Layout'
import { colors, themeStyles, presets } from '../utils/theme'
import _, { get } from 'lodash'

import LicenseProductPageTitle from '../components/LicensePricingPageTitle'
import TechProductBenefits from '../components/TechProductBenefits'
import { rhythm } from '../utils/typography'
import { GatsbyImage } from 'gatsby-plugin-image'
import { style } from 'glamor'

const styles = {
  testimonies: {
    maxWidth: rhythm(40),
    margin: `${rhythm(2)} auto`,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [presets.Tablet]: {
      flexDirection: 'row',
    }
  },
  testimonyContainer: {
    margin: `auto ${rhythm(0.5)}`,
    display: 'flex',
    alignItems: 'center',
    maxWidth: rhythm(25),
    padding: rhythm(1)
  },
  testimony: {
    padding: `0 ${rhythm(1)}`
  },
  testimonyPicture: {
    width: rhythm(10),
  },
  testimonyAuthor: {
    fontWeight: 600,
    color: colors.darkGrey,
  },
  testimonyRole: {
    fontWeight: 200,
    color: colors.darkGrey,
  },
  testimonyMessage: {
    marginBottom: rhythm(0.4),
    color: colors.darkGreen,
  },
  quote: {
    color: colors.green,
    fontWeight: 600,
    fontSeize: rhythm(8),
  }
}
export const GetYourLicensePage = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter
  const {
    showHeaderLinks,
    productLogoKey,
    title,
    videoUrl,
    buttonText,
    productBenefits,
    testimonies,
    metadataTitle,
    metadataDescription,
  } = frontmatter

  return (
    <Layout showLinks={showHeaderLinks} metadataDescription={metadataDescription} metadataTitle={metadataTitle}>
      <LicenseProductPageTitle
        logo={productLogoKey}
        title={title}
        actionText={buttonText}
        videoUrl={videoUrl}
        useVideoUrl={true}
      />
      <TechProductBenefits
        outerCss={themeStyles.contentWidth}
        benefits={productBenefits}
      />
      <div css={styles.testimonies}>
        {_.map(testimonies, (t, key) => {
          return (<Testimony key={key} testimony={t} />)
        })}
      </div>
    </Layout>
  )
}

const Testimony = ({testimony}) => {
  const quote = <span style={styles.quote}>"</span>
  return (
        <div style={styles.testimonyContainer}>
          <GatsbyImage
            css={styles.testimonyPicture}
            image={get(testimony.pictureUrl, 'childImageSharp.gatsbyImageData')}
            alt={`Testimony Picture ${testimony.author}`}
          />
          <div style={styles.testimony}>
            <div style={styles.testimonyMessage}>{quote}{testimony.text}{quote}</div>
            <div style={styles.testimonyAuthor}>{testimony.author} <span style={styles.testimonyRole}>- {testimony.role}</span></div>
          </div>
        </div>
  )
}

GetYourLicensePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        showHeaderLinks: PropTypes.bool,
        productLogoKey: PropTypes.string,
        title: PropTypes.string,
        metadataTitle: PropTypes.string,
        metadataDescription: PropTypes.string,
        videoUrl: PropTypes.string,
        buttonText: PropTypes.string,
        productBenefits: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            subText: PropTypes.string,
          })
        ),
        testimonies: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            author: PropTypes.string,
            role: PropTypes.string,
            pictureUrl: PropTypes.object,
          })
        ),
      })
    })
  })
}

export default GetYourLicensePage

export const pageQuery = graphql`
  query GetYourLicensePageQuery {
    markdownRemark(frontmatter: {templateKey: {eq: "get-your-license"} }) {
      frontmatter {
        showHeaderLinks
        productLogoKey
        title
        metadataTitle
        metadataDescription
        videoUrl
        buttonText
        productBenefits {
          text
          subText
        }
        testimonies {
          author
          role
          text
          pictureUrl {
            childImageSharp {
              gatsbyImageData(width: 128, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`